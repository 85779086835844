.map-wrap {
  position: relative;
  width: 100%;
  height: calc(100vh - 77px); /* calculate height of the screen minus the heading */
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
}
.marker {
    position: absolute;
    width: 40px;
    height: 40px;
    left: -20px;
    top: -20px;
    background-image: url('https://i.imgur.com/8JWzZ4Q.png');
    background-size: cover;
    cursor: pointer;
}
.marker1 {
  position: relative;
  border-radius: 50%;
  background-color: rgba(127, 174, 23, 0.35);
  width: 100%;
  height: 80px;
}