@import "../../../components/global.scss";

.reportStep {
    max-height: 678px;
    padding: 40px;

    .tabRow {
        margin-bottom: 40px;

        .tabButton {
            flex: 1;
            padding: 12px 24px;
            border: none;
            border-radius: 24px;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
            transition: background-color 0.3s ease;
            color: #666666;
            background-color: #f0f0f0;
            margin: 0 8px;

            &.active {
                background-color: $color-brand-primary;
                color: #ffffff;
            }

            &:hover {
                background-color: #ff8f7d;
            }
        }
    }

    .inputRow {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;

        .selectWrapper {
            position: relative;
            width: 100%;

            .selectInput {
                width: 100%;
                padding: 14px 16px;
                padding-right: 40px;
                border: 1px solid $c-border-dark-input;
                border-radius: 8px;
                font-size: 16px;
                color: #616161;
                background-color: #FFFFFF;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;

                &::placeholder {
                    color: #9E9E9E;
                }

                &:focus {
                    border-color: $color-brand-primary;
                    outline: none;
                }
            }

            .selectIcon {
                position: absolute;
                top: 40%;
                right: 16px;
                transform: translateY(-50%);
                pointer-events: none;
                font-size: 16px;
                color: #666666;
            }
        }


        .inputGroup {
            flex: 1;
            margin-right: 16px;

            &:last-child {
                margin-right: 0;
            }

            label {
                display: block;
                margin-bottom: 8px;
                font-size: 16px;
                font-weight: 500;
                color: #212121;
            }

            .textInput,
            .selectInput {
                width: 100%;
                padding: 16px 14px;
                border: 1px solid $c-border-dark-input;
                border-radius: 8px;
                font-size: 16px;
                color: #616161;

                &::placeholder {
                    color: #888888;
                }
            }

        }
    }
}