@import '../../components/global.scss';

.report-modal {
    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        padding: 20px;
        box-sizing: border-box;

    }

    &__content {
        position: relative;
        background: #ffffff;
        border-radius: 36px;
        max-width: 1323px;
        width: 100%;
        box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
        box-sizing: border-box;

        .modalHeader {
            display: flex;
            justify-content: space-between;
            padding: 40px;
            position: relative;
            border-bottom: 1px solid $c-border-dark-input;

            &_content {
                display: flex;
                justify-content: space-between;
                padding: 0 !important;


                width: 100%;
                margin-right: 8px;

                @media (max-width: 1170px) {
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;

                    h2,
                    p {
                        text-align: center;
                    }
                }
            }


            &_ttls {
                display: flex;
                flex-direction: column;

                .title {
                    font-size: 32px;
                    font-weight: 600;
                    color: #212121;
                    margin: 0;
                }

                .subtitle {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 28px;
                    color: #616161;

                }
            }

            .closeButton {
                display: grid;
                justify-content: center;
                align-items: center;
                width: 56px;
                height: 56px;
                border-radius: 28px;
                border: 1px solid $c-border-dark-input;
                background-color: #F5F5F5;
                position: relative;


                &_icon {
                    width: 24px;
                    height: 24px;
                    color: #212121;
                }

                @media (max-width: 600px) {
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                    position: absolute;
                    padding: 5px !important;
                    top: 20px;
                    right: 20px;

                    &_icon {
                        width: 20px;
                        height: 20px;
                    }
                }
            }


            &_divider {
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: $c-border-dark-input;
                bottom: 0;
                left: 0;
            }
        }

        .modalBody {
            max-height: 60vh;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 2px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 8px;
                background-color: #FF7043;
            }
        }

        .modalFooter {
            display: flex;
            padding: 40px 46px;
            justify-content: space-between;
            width: 100%;
            border-top: 1px solid $c-border-dark-input;

            .nextButton,
            .backButton {
                width: 276px;
                height: 56px;
                border-radius: 50px;
            }

        }


        input,
        select {
            width: 100%;
            padding: 12px 16px;
            border: 1px solid #dddddd;
            border-radius: 8px;
            font-size: 16px;
            margin-bottom: 16px;
            box-sizing: border-box;
        }

        input[type="checkbox"] {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 16px;
            height: 16px;
            border: 2px solid grey;
            padding: 6px 6px !important;
            border-radius: 4px;
            background-color: white;
            position: relative;
            cursor: pointer;
            transition: background-color 0.2s ease, border-color 0.2s ease;
        }

        input[type="checkbox"]::after {
            content: '';
            position: absolute;
            top: 0px;
            left: 3px;
            width: 4px;
            height: 8px;
            border: solid transparent;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            opacity: 0;
            transition: opacity 0.2s ease;
        }

        input[type="checkbox"]:checked {
            background-color: #FF7043;
            border-color: #FF7043;
        }

        input[type="checkbox"]:checked::after {
            border-color: white;
            opacity: 1;
        }

        input:focus,
        select:focus {
            outline: none;
            border-color: #ff5733;
        }

        select {
            appearance: none;
            background: url('data:image/svg+xml;base64,...') no-repeat right 16px center;
            background-color: white;
        }


        button {
            background-color: #ff5733;
            color: white;
            padding: 14px 28px;
            border: none;
            border-radius: 8px;
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;
        }

        button:hover {
            background-color: $color-brand-primary;
        }

        button:active {
            background-color: #cc4622;
        }

        .errorText{
            color: $c-error;
        }

        .cancelButton {
            background-color: #f0f0f0;
            color: #333333;
            margin-right: 12px;
        }

        .cancelButton:hover {
            background-color: #e0e0e0;
        }

        .cancelButton:active {
            background-color: #cccccc;
        }


        .stepNavigation {
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
        }

        .selectWrapper {
            position: relative;
            width: 100%;

            .selectButton {
                position: relative;
                background-color: #fff;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                transition: border-color 0.3s ease;

                &:hover {
                    border-color: #ff5722;
                }
            }

            .selectedValue {
                font-size: 16px;
                color: #9e9e9e;
            }

            .selectButton.active .selectedValue {
                color: #ffffff;
            }

            .chevrons {
                display: flex;
                align-items: center;
                color: #9e9e9e;
                font-size: 16px;
                transition: color 0.3s ease;

                .selectButton.active & {
                    color: #ff5722;
                }
            }
        }

        .options {
            position: absolute;
            top: calc(100% + 4px);
            left: 0;
            width: 100%;
            max-height: 150px;
            overflow-y: auto;
            background-color: #fff;
            border-radius: 4px;
            z-index: 1000;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            animation: fadeIn 0.3s ease forwards;
            cursor: pointer;
            transition: background-color 0.3s ease, color 0.3s ease;



            &:not(:last-child) {
                border-bottom: 1px solid #e2eded;
            }

            .option {
                padding: 12px;
                font-size: 16px;
                color: #212121;

                &:hover {
                    background-color: #ff5722;
                    color: #ffffff !important;
                }
            }
        }

        @keyframes fadeIn {
            from {
                opacity: 0;
                transform: translateY(-10px);
            }

            to {
                opacity: 1;
                transform: translateY(0);
            }
        }

    }
}