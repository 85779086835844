@import "../../../components/global.scss";

.app {
  //padding: $scale2;
  margin-left: $navWidth;
  min-height: 100%;
  background-color: $c-bg;
}

.content {
  height: calc(100vh - $headerHeight);
  width: calc(100vw - $navWidth);
}

.growdiv {
  flex-grow: 1;
}

.settingsGear {
  height: 18px;
  width: 18px;
  margin: 12px;
}

.notificationsSVG {
  height: 18px;
  width: 18px;
  margin: 12px;
}

.vbarSVG {
  margin: 12px;
  height: 64px;
}

.profileWrap {
  margin-left: 12px;
  margin-right: 54px;
  display: flex;
  flex-direction: row;
}

.profileImg {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.user {
  display: flex;
  flex-direction: column;
}

.userName {
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  line-height: 150%;
  font-family: "Exo 2", sans-serif;
  color: #718096;
  text-align: left;
}

.signOut {
  cursor: pointer;
  font-size: 12px;
  line-height: 150%;
  font-family: "Exo 2", sans-serif;
  color: #a0afc0;
  text-align: left;
}
.Credits {
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
  font-family: "Exo 2", sans-serif;
  color: #718096;
  text-align: left;
}

.spacedNav {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-left: 20px;

  a {
    color: #2d3748;
    font-weight: 300;
  }
}
