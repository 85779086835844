@import '../../components/global.scss';

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  //border-top: 1px solid $c-border;
  font-family: 'Exo 2', sans-serif;
  font-size: 16px;

  span, address {

    display: block;
    clear: both;
    opacity: 0.7;
    text-align: left;
    font-style: normal;
    font-size: 0.8em;

  }

  span {

    margin-bottom: $scale1;

  }

  nav {

    float: left;
    clear: both;
    margin-bottom: $scale;

    a {

      color: $c-dark;
      font-weight: 500;
      font-size: 0.9em;
      opacity: 0.7;
      text-decoration: none;
      margin-right: $scale1;
      text-transform: uppercase;
      transition: all 0.3s ease-in-out;

      &:hover {

        opacity: 1;
        color: $c-primary;
        text-decoration: none;
        transition: all 0.3s ease-in-out;

      }
    }
  }
}

.contentWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
