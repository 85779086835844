.testHolder {
  //position: absolute;
  //right: 1%;
  //top: 15%;
  width: 100%;
  //height: 20%;
  border-radius: 15px;
  background: linear-gradient(
    112.83deg,
    rgba(255, 255, 255, 0.82),
    rgba(255, 255, 255, 0.8)
  );
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(21px);
  opacity: 0.9;
  padding: 24px;
  display: flex;
  flex-direction: column;
  z-index: 6;
  overflow-y: scroll;
}

.text1 {
  position: relative;
  font-size: 12px;
  letter-spacing: 0.02em;
  line-height: 150%;
  display: inline-block;
  font-family: "Exo 2";
  color: #a0aec0;
  text-align: left;
  width: 111px;
  height: 18px;
}

.placeWrapper {
  display: flex;
  flex-direction: row;
  //align-items: center;
}

.placeIcon {
  width: 36px;
  height: 36px;
  margin-right: 8px;
}

.text2 {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  line-height: 140%;
  font-family: "Exo 2";
  color: #2d3748;
  text-align: left;
  margin-bottom: 15px;
}

.text3 {
  position: relative;
  font-size: 17px;
  line-height: 140%;
  font-weight: 500;
  font-family: "Exo 2";
  color: #2d3748;
  text-align: left;
}

.text4 {
  position: relative;
  font-size: 12px;
  line-height: 140%;
  font-family: "Exo 2";
  color: #8c98a7;
  text-align: left;
  display: inline-block;
  margin-bottom: 14px;
  //width: 570px;
}

.text5 {
  position: relative;
  font-size: 17px;
  line-height: 140%;
  font-weight: 600;
  font-family: "Exo 2";
  color: #7fae17;
  text-align: left;
}
.text5b {
  margin-top: 24px;
  position: relative;
  font-size: 17px;
  line-height: 140%;
  font-weight: 600;
  font-family: "Exo 2";
  color: #7fae17;
  text-align: left;
}

.text6 {
  position: relative;
  font-size: 12px;
  line-height: 140%;
  font-family: "Exo 2";
  margin-top: 6px;
  margin-bottom: 10px;
  color: #8c98a7;
  text-align: left;
  display: inline-block;
}

.text7 {
  position: relative;
  font-size: 16px;
  line-height: 140%;
  font-weight: 600;
  font-family: "Exo 2";
  color: #7fae17;
  text-align: left;
  margin-bottom: 14px;
}
.text8 {
  position: relative;
  font-size: 14px;
  line-height: 140%;
  font-family: "Exo 2";
  margin-top: 6px;
  margin-bottom: 10px;
  color: #8c98a7;
  text-align: left;
  display: inline-block;
}

.test_text {
  font-family: "Exo 2";
}

.rbWrapper {
  width: 100%;
}

.chartWrapper {
  background: linear-gradient(77.72deg, rgba(248, 249, 250, 0.98), #eceef0);
  border-radius: 12px;
}

.tabsWrapper {
  display: flex;
  flex-direction: row;
}

.tab {
  margin-right: 8px;
}

.climateHazardHeadline {
  font-size: 18px;
  line-height: 140%;
  font-family: "Exo 2";
  color: #2d3748;
}

.climateHazardText {
  position: relative;
  font-size: 14px;
  line-height: 140%;
  font-family: "Exo 2";
  color: #8c98a7;
  text-align: left;
  display: inline-block;
  margin-bottom: 14px;
}

.statGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.stat {
  background-color: white;
  border-radius: 10px;
  padding: 15px;
}

.stat p {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  font-family: "Exo 2";
  color: #29332b;
}

.statData p {
  font-size: 18px;
  font-family: "Exo 2";

  span {
    font-size: 14px;
    font-weight: 400;
  }
}

.lineWrapper {
  height: 4px;
  background-color: #e2e8f0;
  border-radius: 25px;

  .statLine {
    height: 4px;
    border-radius: 25px;
  }
}
