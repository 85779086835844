@import '../../../components/global.scss';

.nav {

  height: $scale8;
  background-color: $c-darkpurple;
  background-image: radial-gradient(ellipse at top, #a458a3, #75517c);
  width: 100%;
  position: absolute;
    top: 0;
    left: 0;

  > div {

    height: 100%;

  }

  nav {

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;

    a {
      width: 70px;
      margin-right: $scale;
      margin-left: $scale;
      &:last-child {
        width: 170px;
        margin-right: 0;
      }
    }
  }
}

.cta {
  width: 170px;
  height: 48px;
}

.logo {
  margin-left: 100px;
  margin-top: 36px;
  width: 200px;

}

.linksHolder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  width: 100%;
  margin-right: 5%;
}

.navStyle {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.transparent {

  background-image: none;
  background-color: transparent;

}
