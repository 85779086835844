@import "../../../components/global.scss";

.switchCard {
    display: flex;
    align-items: center;
    width: 220px;
    padding: 6px;
    background-color: #F7EAE8;
    border-radius: 16px;
    transition: box-sfhadow 0.3s ease;
    margin-top: 24px;
    cursor: pointer;
    border: 1px solid #E0E0E0;

    &:hover {
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    }

    .logoWrapper {
        width: 50px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background-color: white;
        margin-right: 12px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .contentWrapper {
        display: flex;
        flex: 1;
        flex-direction: column;

        .cardTitle {
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            color: $c-dark-title;

            .icon {
                margin-left: 4px;
            }
        }

        .cardDescription {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            color: $c-text-light;
        }
    }
}