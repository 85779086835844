@import '../../components/global.scss';

.logo {

  display: block;
  //width: $scale12;
  //height: $scale4;
  //margin: 0 auto $scale2;
  //width: auto;
  //height: 100%;
  outline: none;

}

.img {
  width: 100%;
  height: 100%;
}

.label {
  margin-left: 1em;
  color: $c-bg;
}

.wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
}
