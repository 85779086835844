@import "../../components/global.scss";

.btn {
  font-family: $brand-font-family;
  min-height: 48px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: white;
  font-size: $scale;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: $scale $scale4;
  border-radius: $btnRadius;
  background-color: $color-brand-primary;
  transition: all 0.4s ease-in-out;

  &:hover {
    background-color: darken($color-brand-primary, 12%);
    transition: all 0.2s ease-in-out;
  }
}

.red {
  background-color: $c-red;

  &:hover {
    background-color: darken($c-red, 5%);
  }
}

.blue {
  background-color: $c-blue;

  &:hover {
    background-color: darken($c-blue, 5%);
  }
}

.gray {
  background-color: $c-gray;

  &:hover {
    background-color: darken($c-gray, 5%);
  }
}

.link {
  font-family: $brand-font-family;
  font-size: $scale1-1;
  font-weight: 500;
  background-color: transparent;
  color: $color-brand-primary;
  text-decoration: none;

  &:hover {
    background-color: transparent;
    color: $color-brand-primary;
    font-weight: 600;
  }
}

.small {
  font-size: 0.9em;
  padding: 0.8em $scale2;
  min-height: 38px;
}

.very_small {
  font-size: 0.5em;
  padding: 0.5em 0.5em;
  max-height: 3em;
  margin: 1em;
}

.big {
  @media (min-width: $mobile) {
    font-size: $scale1;
  }
}

.loader {
  position: relative;
  display: inline-block;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
  border-radius: $radius;

  &:after {
    display: none;
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    right: 1em;
    top: 1.05em;
    z-index: 3;
    background-size: contain;
    background-image: url("./icons/ico-loader.svg");
  }

  input,
  button {
    display: block;
    width: 100% !important;
    cursor: pointer;
  }
}

.disabled {
  cursor: default;
}

.loading {
  &:after {
    // loading spinner
    display: block;
    transform-origin: center center;
    -webkit-animation: rotate 1s infinite linear;
    -moz-animation: rotate 1s infinite linear;
    -o-animation: rotate 1s infinite linear;
    animation: rotate 1s infinite linear;
  }
}

.outline {
  color: $c-text;
  background: transparent;
  border: 1px solid $c-border-dark;

  &:hover {
    color: white;
    border-color: $c-red;
    background: $c-red;
  }
}

.text {
  cursor: pointer;
  color: $c-text;
}

.icon {
  display: inline-block;
  padding: 0;
  width: $scale1;
  height: $scale1;

  svg {
    width: 100%;
    height: 100%;
  }
}

.iconLabel {
  position: absolute;
  top: 50%;
  margin-left: calc($scale / 2);
  font-size: $scale;
  color: $c-text;
  transform: translateY(-50%);
}

.fullWidth {
  width: 100%;
}

.pullRight {
  float: right;
}
