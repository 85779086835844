@import "../../../components/global.scss";

.report-step {
    padding: 32px;

    .asset-summary {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 16px;
        margin-bottom: 40px;

        p {
            font-size: 20px;
            font-weight: 400;
            color: #616161;
            margin: 0 !important;
        }

        &__count,
        &__type {
            font-size: 20px;
            font-weight: 400;
            color: #212121;
        }
    }

    .upload-section {
        display: flex;
        flex-direction: column;

        &__title {
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            color: #212121;
            margin: 0;
        }

        &__subtitle {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #616161;
        }

        &__template-link {
            margin-left: 3px;

            &:hover {
                cursor: pointer;
                text-decoration-line: underline;
                text-decoration-color: $color-brand-primary;
            }
        }
    }

    .file-upload {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 176px;
        padding: 40px;
        background-color: #f9f9f9;
        border-radius: 12px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            inset: 0;
            padding: 1px;
            border-radius: 10px;
            background: repeating-conic-gradient(#757575 0 20%, transparent 0 50%) 0 0/12px 12px round;
            -webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
            pointer-events: none;
        }

        &__label {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 3px;
            padding: 8px 24px;
            background-color: $color-brand-primary;
            font-size: 16px;
            color: #ffffff;
            font-weight: bold;
            border-radius: 50px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: darken($color-brand-primary, 5%);
            }

            .file-upload__input {
                display: none;
            }

            .file-upload__icon {
                width: 18px;
                height: 18px;
            }
        }

        p {
            margin-top: 12px;
            font-size: 14px;
            color: #888888;
        }
    }

    .divider {
        display: flex;
        align-items: center;
        text-align: center;
        margin: 20px 0;

        &::before,
        &::after {
            content: '';
            flex: 1;
            border-bottom: 1px solid;
            margin: 0 10px;
            opacity: 0.5;
            height: 1.5px;
        }

        span {
            font-size: 14px;
            color: #777;
            font-weight: bold;
        }
    }

    .asset-entry {
        display: flex;
        justify-content: space-between;

        &__title {
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            color: #212121;
            margin: 0;
        }

        &__subtitle {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #616161;
        }

        &__add-button {
            width: 152px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 50px;
            font-size: 14px;
            font-weight: 600;
            color: #ffffff;
            background-color: $color-brand-primary;
            cursor: pointer;
            padding: 0;

            &:hover {
                background-color: darken($color-brand-primary, 10%);
            }

            &__add-icon {
                width: 18px;
                height: 18px;
            }
        }
    }

    .data-entry {
        margin-top: 24px;

        .data-table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;
            margin-bottom: 24px;
            border-radius: 12px;
            overflow: hidden;
            border: 1px solid $c-border-dark-input;

            th,
            td {
                padding: 8px 16px;
                border-bottom: 1px solid $c-border-dark-input;
                border-right: 1px solid $c-border-dark-input;
                font-size: 14px;
                text-align: center;
            }

            th {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                text-align: left;
                color: #212121;
            }

            td:last-child,
            th:last-child {
                border-right: none;
            }

            tr:last-child td {
                border-bottom: none;
            }

            input {
                width: 100%;
                padding: 8px;
                border-radius: 4px;
                border: none;
                color: #555;
                font-size: 14px;
                outline: none;

                &::placeholder {
                    color: #9E9E9E;
                }

                &:focus {
                    border-color: $color-brand-primary;
                    box-shadow: none;
                }
            }
        }
    }

    .note {
        &__title {
            font-size: 20px;
            font-weight: 600;
            margin: 0;
            line-height: 28px;
            color: #616161;
            margin-bottom: 4px;
        }

        &__subtitle {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #9E9E9E;
            text-align: left;
        }
    }
}