@import '../../../components/global.scss';

.groupChild {
  //position: absolute;
  //top: -1.5px;
  //left: -1.5px;
  border-radius: 15px;
  background: linear-gradient(112.83deg, rgba(255, 255, 255, 0.82), rgba(255, 255, 255, 0.8));
  box-shadow: 0px 1.9999998807907104px 5.5px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(3px);
  border: 1.5px solid #fff;
  box-sizing: border-box;
  //width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: 5;
  margin-right: 24px;
}
.groupChild:focus-within {
  outline: 1px solid $color-brand-primary;
  backdrop-filter: blur(5px);
  opacity: 0.95;
}
.icon1 {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.addons {
  position: relative;
  line-height: 150%;
  display: none;
  align-items: center;
  width: 24px;
  height: 10.5px;
  flex-shrink: 0;
}
.addon {
  height: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 4px 6.000000476837158px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}
.autoAddedFrame {
  position: absolute;
  top: 13px;
  left: 17px;
  width: 37.5px;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.typeLocationHere1 {
  position: relative;
  line-height: 150%;
  width: 100%;
  font-family: 'Exo 2', sans-serif;
  background-color: transparent;
}

.content1 {
  position: relative;
  width: 0.01px;
  height: 0.01px;
}
.minwidth {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 0px 74.00000762939453px;
  align-items: center;
  justify-content: center;
}
.inputfieldtext {
  position: absolute;
  top: 24.5px;
  left: 60.5px;
  height: 17px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 14px;
  color: #a0aec0;
  width: 100%;
}
.rectangleParent {
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 64px;
  text-align: left;
  font-size: 7px;
  color: #000;
  font-family: Helvetica, sans-serif;
  border-radius: 15px;
}

.groupChild1 {
  position: absolute;
  transform: translateY(64px);
  z-index: 5;
  top: -1.5px;
  left: -1.5px;
  //border-radius: 15px;
  //background: linear-gradient(112.83deg, rgba(255, 255, 255, 0.82), rgba(255, 255, 255, 0.8));
  //box-shadow: 0px 1.9999998807907104px 5.5px rgba(0, 0, 0, 0.02);
  //backdrop-filter: blur(21px);
  //border: 1.5px solid #fff;
  //box-sizing: border-box;
  width: 200px;
  height: 200px;
  //opacity: 0.95;
}

