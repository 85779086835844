@import "../../../components/global.scss";

.report-step {
    padding: 24px;
    background-color: #fff;
    border-radius: 8px;

    .section {
        margin-bottom: 32px;

        &-title {
            font-size: 20px;
            font-weight: 600;
            color: #212121;
            margin-bottom: 16px;
        }

        &-subtitle {
            font-size: 16px;
            color: #616161;
            margin-bottom: 12px;
        }

        .checkbox-group {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            margin-top: 16px;

            .checkbox-label {
                display: flex;
                align-items: center;
                gap: 10px;
                padding: 10px 18px;
                border-radius: 50px;
                border: 1px solid $c-border-dark-input;
                font-size: 18px;
                font-weight: 600;
                text-align: center;
                cursor: pointer;
                transition: background-color 0.2s;

                @media (max-width: 1170px) {
                    align-items: center;
                    gap: 10px;

                    h2,
                    p {
                        text-align: center;
                    }
                }

                &:hover {
                    background-color: #E0E0E0;
                }
            }
        }

        .data-table,
        .specs-table,
        .total-price {
            margin-top: 24px;
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;
            border: 1px solid #E0E0E0;
            border-radius: 12px;
            overflow: hidden;
            font-size: 16px;
            font-weight: 600;
            color: #212121;

            .sectionTitle,
            .sectionSubtitle {
                margin: 0;

            }

            th,
            td {
                padding: 16px;
                border-right: 1px solid #E0E0E0;
                border-bottom: 1px solid #E0E0E0;
                text-align: left;
            }

            th {
                font-weight: 600;
            }

            td {
                background-color: #FFF;

                select,
                label {
                    width: 100%;
                    padding: 0 !important;
                    border: none;
                }
            }

            td:last-child,
            th:last-child {
                border-right: none;
            }

            tr:last-child {
                border-bottom: none !important;
            }



            td:nth-child(2) {
                width: 35%;
            }

            td:nth-child(3),
            td:nth-child(4),
            td:nth-child(5),
            td:nth-child(6) {
                width: 15%;
            }

            .file-upload-label {
                color: #FF7043;
                cursor: pointer;
                transition: color 0.2s;

                &:hover {
                    color: #FF5722;
                }
            }

            @media (max-width: 900px) {
                display: block;
                width: 100%;
                overflow-x: auto;
                white-space: nowrap;

                th,
                td {
                    white-space: nowrap;
                }

                &.data-table {

                    th:first-child,
                    td:first-child,
                    th:last-child,
                    td:last-child {
                        position: sticky;
                        background-color: #fff;
                        z-index: 2;
                    }

                    th:first-child,
                    td:first-child {
                        left: 0;
                    }

                    th:last-child,
                    td:last-child {
                        right: 0;
                    }
                }

                &.specs-table {

                    th:last-child,
                    td:last-child {
                        position: sticky;
                        right: 0;
                        background-color: white;
                        z-index: 2;
                    }
                }

                &.total-price {

                    th:last-child,
                    td:last-child {
                        position: sticky;
                        right: 0;
                        background-color: white;
                        z-index: 2;
                    }
                }
            }
        }

        .specs-table {
            width: 100%;
            margin-top: 24px;
            border: 1px solid #E0E0E0;
            border-radius: 12px;
            overflow: hidden;
            position: relative;

            th,
            td {
                padding: 16px;
                border-right: 1px solid #E0E0E0;
                text-align: left;
                font-size: 16px;
                font-weight: 600;
                color: #212121;
                background-color: #fff;
            }

            th {
                background-color: #f9f9f9;
            }

            td:first-child {
                padding: 16px 24px;
            }

            td:nth-child(2) {
                width: 15% !important;
            }

            td {
                background-color: #FFF;
            }

            td[colspan="2"] {
                font-weight: 600;
                font-size: 18px;
                color: #212121;
                padding-bottom: 0;
            }

            @media (max-width: 900px) {
                display: block;
                overflow-x: auto;
                white-space: nowrap;

                th,
                td {
                    white-space: nowrap;
                }

                th:last-child,
                td:last-child {
                    position: sticky;
                    right: 0;
                    background-color: white;
                    z-index: 1;
                }
            }
        }

        .total-price {
            background-color: #EBF1D9 !important;
            border-radius: 8px;
            text-align: right;
            margin-top: 24px;
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;
            border-radius: 12px;
            overflow: hidden;

            th,
            td {
                text-align: left;
            }

            th {
                font-weight: 600;
            }

            td {
                background-color: #EBF1D9 !important;

                select,
                label {
                    width: 100%;
                }
            }

            td:nth-child(2) {
                width: 15% !important;
            }

            &-title {
                font-size: 16px;
                font-weight: 600;
                color: #212121;
                text-align: right;
                font-family: Exo 2;
                line-height: 32px;
                text-align: right;
            }

            &-value p:first-child {
                font-size: 24px;
                font-weight: 700;
                color: #212121;
                margin: 0;
            }

            &-value p:last-child {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                text-align: left;
                color: #9E9E9E;
            }
        }

    }

}

.checkbox-label {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #212121;

    input[type="checkbox"] {
        width: 16px;
        height: 16px;
        border: 2px solid $c-border-dark-input;
        border-radius: 4px;
        margin-right: 2px;
        margin-bottom: 0 !important;
    }
}

.file-upload-label {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    padding: 8px 24px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 50px;
    cursor: pointer;

    .file-input {
        display: none;
    }
}