@import '../../../components/global.scss';

nav.sidebar {
  display: flex;
  flex-direction: column;

  @media (min-width: $mobile) {

    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #e2e8f0;
    //width: $scale7;  // Old Value
    // TODO: update this based on window size responsive
    width: $navWidth;
    height: 100%;
    z-index: 9;
    //padding: $scale;
    background-color: #FFFFFF;
    transition: all 0.2s ease-out;

    &.expanded {

      //TODO: responsive:
      width: $navWidth;

      a span.label {

        opacity: 1;
        transition: all 0.8s ease-in-out;

      }
    }

    >a {
      // logo

      width: auto;
      height: 50px;
      margin-top: 25px;
      margin-bottom: 28px;

      //position: absolute;
      //top: $scale2;
      //left: $scale2;
      //width: $scale3;
      //height: $scale3;

    }

    .divider {
      width: 100%;
      height: 1px;
      margin-bottom: 28px;
    }

    .nav-memnu {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 56px;
    }

    .nav-links {
      padding: 0;
      margin-top: $scale7;

      a {

        position: relative;
        display: block;
        text-decoration: none;
        color: white;
        padding: 0;
        width: 100% !important;
        height: $scale5;
        padding: $scale calc($scale/2);
        font-size: 1em;
        line-height: 1;

        .icon {

          position: absolute;
          top: 50%;
          left: $scale-1;
          transform: translateY(-50%);

        }

        .navimg {
          position: absolute;
          height: auto;
        }

        .label {

          opacity: 0;
          pointer-events: none;
          margin-left: $scale4;

        }

        &.active {

          color: white;
          border-radius: $radius;
          background-color: $c-blue;
          background: lighten($c-dark, 5%);

        }
      }
    }
  }
}

nav.mobile-fixed {

  // moves navigation to bottom
  // of viewport like iOS app
  @media (max-width: $mobile) {

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;
    height: auto;
    padding: 0;
    background-color: #FFFFFF;
    border-bottom: none;

    >a {

      display: none;

    }

    .nav-links {

      position: relative;
      top: auto;

    }

    a {

      position: relative;
      float: left;
      margin: 0;
      height: auto;
      line-height: 1;
      border: none;
      color: white;
      padding: $scale1 0;
      font-size: 0.9em;
      text-align: center;

      .ico {

        position: relative;
        top: -0.2em;

      }

      .label {

        position: absolute;
        bottom: 0.65em;
        left: 50%;
        font-size: 0.8em;
        transform: translateX(-50%);

      }

      &.active {

        background-color: lighten($c-dark, 2%);

      }
    }
  }

  .btn-togglenav {

    display: none;

  }

  a {

    text-decoration: none;

  }
}

nav.mobile-popup {

  @media (min-width: $mobile) {

    .btn-togglenav {

      display: none !important;

    }
  }

  @media (max-width: $mobile) {

    position: fixed;
    top: $scale1;
    left: $scale1;
    width: $scale2;
    height: $scale2;
    z-index: 10;
    background-color: transparent;

    >a {

      display: none;

    }

    .btn-togglenav {

      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;

    }

    &.open {
      .nav-links {

        display: block !important;

      }
    }

    section.nav-links {
      position: fixed;
      display: none !important;
      padding: $scale;
      z-index: 9;
      top: $scale-2;
      left: calc($scale/2);
      right: calc($scale/2);
      padding-top: $scale5;
      background-color: white;
      border-radius: $radius;
      box-shadow: 0 0 5em rgba(0, 0, 0, 0.25);
      transition: all 0.2s ease-in-out;

      a {

        position: relative;
        display: block;
        float: none;
        color: $c-text;
        padding: 0;
        width: 100%;
        line-height: 1;
        margin-bottom: $scale;
        font-size: 1.15em;
        text-decoration: none;

        .ico {

          position: absolute;
          top: 50%;
          transform: translateY(-50%);

        }

        .label {

          margin-left: $scale-2;

        }

        &:last-child {

          margin-bottom: 0;

        }

        &.active {

          color: $c-text;
          background: none;

        }
      }
    }
  }
}

.sageCard {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 14px;
  flex-direction: column;
  align-items: center;
  margin-left: 31px;
  margin-right: 31px;
  position: relative;
  border-radius: 15px;
  background-color: #f3e6dc;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
}

.sageCardLogoDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: #FFFFFF;
  margin-top: 14px;
  margin-bottom: 12px;
  height: 38px;
  width: 185px;

  img {
    height: 24px;
    width: 63px;
  }
}

.cardTitle {
  position: relative;
  font-size: 18px;
  line-height: 140%;
  font-weight: 600;
  font-family: 'Exo 2', sans-serif;
  align-self: self-start;
  color: #2d3748;
  text-align: left;
}

.cardDescription {
  position: relative;
  font-size: 14px;
  line-height: 140%;
  font-family: 'Exo 2', sans-serif;
  margin-top: 6px;
  margin-bottom: 18px;
  color: #2d3748;
  text-align: left;
  display: flex;
  align-items: center;
  width: 185px;
  height: 35px;
}

.buttonWrapper {
  cursor: pointer;
  position: relative;
  border-radius: 12px;
  background-color: #ef6239;
  width: 100%;
  height: 35px;
  display: flex;
  flex-direction: row;
  padding: 0px 8px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 4px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  font-family: 'Exo 2', sans-serif;

  .fg {
    flex-grow: 1;
  }

  .text {
    position: relative;
    line-height: 150%;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 98px;
    height: 12px;
    flex-shrink: 0;
  }

  .backSVG {
    height: 16px;
    width: 16px;
  }
}

.restCard {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 14px;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  margin-left: 31px;
  margin-right: 31px;
  position: relative;
  border-radius: 15px;
  background-color: #ebf1d9;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
}

.restCardLogoDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: #FFFFFF;
  margin-top: 14px;
  margin-bottom: 12px;
  height: 38px;
  width: 185px;

  img {
    height: 24px;
    width: 63px;
  }
}

.restButtonWrapper {
  cursor: pointer;
  position: relative;
  border-radius: 12px;
  background-color: #7ca819;
  width: 100%;
  height: 35px;
  display: flex;
  flex-direction: row;
  padding: 0px 8px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: 4px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  font-family: 'Exo 2', sans-serif;

  .fg {
    flex-grow: 1;
  }

  .text {
    position: relative;
    line-height: 150%;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 98px;
    height: 12px;
    flex-shrink: 0;
  }

  .backSVG {
    height: 16px;
    width: 16px;
  }
}

.generateReportButton {
  background-color: #ff5733;
  width: 220px;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  text-align: left;
  display: flex;
  align-items: center;

}

.generateReportButton:hover {
  background-color: $color-brand-primary;
}