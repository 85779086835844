@import 'components/global.scss';

.disabled {

  cursor: not-allowed;
  pointer-events: none;

}

.overlay {
    position: absolute;
  opacity: .45;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: repeating-linear-gradient(45deg, #d6d6d6 0px, #d6d6d6 20px, #f0f0f0 20px, #f0f0f0 40px);
    //background-size: 39.60px 39.60px;
}
