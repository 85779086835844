@import 'components/global.scss';

.auth {

  span {

    display: block;
    width: 100%;
    text-align: center;
    margin-top: $scale1;

  }
}
