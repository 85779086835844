@import 'components/global.scss';

.back_row {
    background-color: white;
    margin-left: -$scale1;
    margin-top: -$scale2;
    box-shadow: 5px 5px 12px -10px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 12px -10px rgba(0, 0, 0, 0.5);
}

.back_btn {
    color: $c-blue;
    margin-left: 1em;
    margin-top: 1em;
    margin-bottom: 1em;

    &:hover {
        cursor: pointer;
    }
}