@import '../../components/global.scss';
.ani_pop {
  height: 100%;
  width: 100%;
  position: relative;
}

.product_row {
  display: inline-flex;

  div {
    align-self: center;
    margin-left: 3em;
  }

  div span {
  }

}

.map_controls_holder {
  position: absolute;
  // top: $headerHeight; // now relative
  top: 0px;
    // left: $navWidth;
    left: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: calc(.6*(100vw - $navWidth));
  z-index: 5;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
}

.report1_holder {
  position: absolute;
  // top: $headerHeight;
  top: 0px;
  right: 0;
    display: flex;
  width: calc( .4*(100vw - $navWidth));
  max-height: calc(100vh - $headerHeight - 48px);
  overflow-y: scroll;
  z-index: 5;
  padding-right: 24px;
  padding-top: 24px;
}

.textTest {
  font-weight: 600;
  padding-left: 35px;
  border-radius: 54px 54px 54px 54px;
  -webkit-border-radius: 54px 54px 54px 54px;
  -moz-border-radius: 54px 54px 54px 54px;
  background: #f3e6dc;
  //width: 40%;
  overflow: hidden;
  z-index: 6;
}
.DisclaimerWrap{
  position: absolute;
  bottom: 1%;
  margin-right: 0;
  display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;

  width: 100%;
  overflow: hidden;
  z-index: 6;
}