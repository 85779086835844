@import 'components/global/scale';

.content {

  position: relative;
  max-width: $max-width;
  margin: 0 auto;

  &:after {

    content: "";
    clear: both;
    display: table;

  }

  @media (max-width: $mobile) and (max-width: $max-width){

    margin-left: $scale;
    margin-right: $scale;

  }

  @media (min-width: $mobile) and (max-width: $max-width){

    margin-left: $scale2;
    margin-right: $scale2;

  }

  @media (min-width: $max-width + 200px){

    margin-left: auto;
    margin-right: auto;

  }
}
