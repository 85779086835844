/* colours */
$c-bg: #F3F3FA;
$c-text: #707070;
$c-text-light: lighten($c-text, 20%);
$c-border: darken(white, 5%);
$c-border-dark: lighten($c-text, 52%);
$c-border-dark-input: #BDBDBD;

$c-primary: #73B0F4;

:export {
  cprimary: $c-primary;
}

$c-dark: #333440;
$c-dark-title: #212121;
$c-error: #b50000;

$c-facebook: #3b5998;
$c-twitter: #55acee;
$c-instagram: #405de6;
$c-youtube: #cd201f;
$c-pinterest: #bd081c;
$c-linkedin: #0077b5;

$c-red: #d95565;
$c-green: #8CC57D;
$c-blue: #73B0F4;

:export {
  cblue: $c-blue;
}

$c-brandblue: #4fc1d1;

:export {
  cbrandblue: $c-brandblue;
}

$c-darkblue: #3A4488;
$c-orange: #F0AA61;
$c-tint: #FBFBFF;
$c-purple: #6363AC;
$c-darkpurple: #556CB6;
$c-gray: #b6b6b6;

:export {
  cgray: $c-gray;
}

$c-lightgray: #d2d2d2;

:export {
  clightgray: $c-lightgray;
}

// This is the new Color scheme, it is a work in progress, added by @josh, Aug 2023
$color-brand-primary: #ef6239;