.table_header {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1.5em;
  margin-right: 1em;

  Icon {
    margin-left: 1em;
  }

}

.table_header_icn {
  width: 1.5em;
  height: 1.5em;
}

.description {
  display: flex;
  margin-bottom: 1.5em;
  align-items: center;
}

.sage_logo {
  margin-right: 2em;
}

.button_holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1.5em;
}