@import "../../../components/global.scss";

.report-step {
    display: flex;
    justify-content: space-between;
    padding: 32px;
    position: relative;

    .order-summary-drawer {
        flex: 1;
        padding-right: 60px;
        border-right: 1px solid $c-border-dark-input;
        position: relative;
        transition: left 0.3s ease-in-out;

        &.drawer-open {
            left: 0;
        }

        h3 {
            font-size: $scale3;
            font-weight: bold;
            color: $c-dark-title;
            margin-bottom: 32px;
        }

        .order-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            background-color: #f5f5f5;
            border-radius: 12px !important;
            margin-bottom: 8px;

            &-price {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 8px;

                span:first-child {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 28px;
                    color: $c-dark-title;
                }

                span:last-child {
                    font-size: 1em;
                    font-weight: 500;
                    line-height: 24px;
                    color: #757575;
                    text-align: right;
                }
            }

            p {
                font-size: 20px;
                font-weight: 500;
                line-height: 28px;
                color: $c-dark-title;
            }
        }

        .subtotal-section,
        .tax-section,
        .total-price-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px 0;
        }

        .subtotal-section {
            margin-top: 56px;

            .left,
            .right {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }

            .right {
                text-align: right;
            }

            p {
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;
                margin-bottom: 0 !important;
                color: $c-dark-title;
            }

            .subnote {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                color: #9e9e9e;
            }
        }

        .tax-section {
            border-top: 1px solid $c-border;

            p {
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;
                color: $c-dark-title;
            }
        }

        .total-price-section {
            padding-top: 12px;
            border-top: 1px solid $c-border;

            p {
                font-size: 24px;
                font-weight: 600;
                line-height: 32px;
                color: $c-dark;
            }

            span {
                font-size: 24px;
                font-weight: bold;
                color: $c-dark-title;
            }
        }
    }

    .payment-details {
        flex: 1;
        padding-left: 60px;
        background-color: #ffffff;
        border-radius: 12px;

        &-content {
            display: flex;
            flex-direction: column;
            gap: 24px;

            input {
                margin-bottom: 0 !important;
            }
        }

        h3 {
            font-size: $scale3;
            font-weight: bold;
            color: $c-dark-title;
            margin-bottom: 32px;
        }

        .input-field {
            margin-bottom: 0px;

            &-select {
                width: 100%;
                padding: 9px 14px;
                border: 1px solid $c-border-dark-input;
                border-radius: 12px !important;
                font-size: 16px;
            }

            label {
                display: block;
                font-size: 14px;
                margin-bottom: 4px;
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                color: $c-dark-title;
            }

            input,
            select {
                width: 100%;
                padding: 9px 14px;
                border: 1px solid $c-border-dark-input;
                border-radius: 12px !important;
                font-size: 16px;

                &:focus {
                    outline: none;
                    border-color: $color-brand-primary;
                }
            }

            &.invalid input {
                border-color: $c-error !important;
            }

            .error-text {
                color: $c-error;
                font-size: 12px;
                margin-top: 4px;
            }

            .input-with-icon {
                display: flex;
                align-items: center;
                border: 1px solid $c-border-dark-input;
                border-radius: 12px !important;

                input {
                    border: none;
                    flex-grow: 1;
                    font-size: 16px;
                }

                .icon {
                    font-size: 20px;
                    margin-right: 14px;
                }
            }
        }

        .row {
            display: flex;
            gap: 16px;
            position: relative;

            .input-field {
                flex: 1;
            }

            .city-suggestions {
                position: absolute;
                right: 0;
                top: 70px;
                z-index: 100000;
                background-color: #fff;
                border: 1px solid #ccc;
                border-radius: 8px;
                width: 100%;
                max-height: 150px;
                overflow-y: auto;

                li {
                    padding: 10px;
                    cursor: pointer;

                    &:hover {
                        background-color: #f5f5f5;
                    }
                }
            }

        }

        .checkbox-field {
            display: flex;
            align-items: center;
            margin: 16px 0;

            input {
                margin-right: 22px;
                width: 16px;
                height: 16px;
            }

            span {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: #000000;
            }
        }

        .error-message {
            margin-bottom: 10px;
            color: #ff0000;
        }

        .submit-button {
            display: block;
            width: 100%;
            padding: 12px;
            background-color: $color-brand-primary;
            color: white;
            font-size: 16px;
            font-weight: bold;
            border: none;
            border-radius: 50px;
            cursor: pointer;
            text-align: center;

            &:hover {
                background-color: darken($color-brand-primary, 5%);
            }
        }

        .submit-button-subtitle {
            margin-top: 24px;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #757575;
        }
    }

    .drawer-toggle {
        display: none;
        position: fixed;
        top: 53%;
        left: 5%;
        background-color: #ffffff;
        color: #fff;
        border: none;
        padding: 10px;
        border-radius: 50px;
        cursor: pointer;
        z-index: 20;

        svg {
            font-size: 24px;
            color: $color-brand-primary;
        }

        @media (max-width: 900px) {
            display: block;
        }
    }

    .drawer-toggle-close {
        display: none;
        position: absolute;
        top: 2%;
        right: 3%;
        background-color: #ffffff;
        color: #fff;
        border: none;
        border-radius: 50px;
        padding: 10px;
        cursor: pointer;
        z-index: 20;

        svg {
            font-size: 24px;
            color: $color-brand-primary;
        }

        @media (max-width: 900px) {
            display: block;
        }
    }

    // Drawer-style behavior for screens smaller than 900px
    @media (max-width: 900px) {
        .order-summary-drawer {
            position: absolute;
            top: 0;
            left: -100%;
            width: 80%;
            background-color: #fff;
            z-index: 99;
            transition: left 0.3s ease-in-out;
            padding: 16px;
            box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
            border-radius: 0 0 0 50px;

            &.drawer-open {
                left: 0;
            }
        }
    }
}