.rectangleDiv {
  position: absolute;
  transform: translateY(64px);
  z-index: 4;
  top: -1.5px;
  left: -1.5px;
  border-radius: 15px;
  background: linear-gradient(112.83deg, rgba(255, 255, 255, 0.82), rgba(255, 255, 255, 0.8));
  box-shadow: 0px 1.9999998807907104px 5.5px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(21px);
  width: 100%;
  height: 384px;
  opacity: 0.95;
}
.icon1 {
  position: relative;
  width: 15px;
  height: 15px;
  margin-left: 28px;
  margin-right: 14px;
}
.resultLabel1 {
  position: relative;
  font-size: 14px;
  line-height: 150%;
  font-family: 'Exo 2', sans-serif;
  color: #2d3748;
  text-align: left;
}
.rowItem {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}
.autoAddedFrame {
  height: 100%;
}
.rowItem:hover {
  cursor: pointer;
  background-color: rgba(235,241,217,0.35);
  box-shadow: 0px 1.9999998807907104px 5.5px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(21px);
}
.groupDiv {
  height: 64px;
}
.lineDivider {
  position: relative;
  margin-left: 56px;
  border-top: 1px solid #e0e4e8;
  box-sizing: border-box;
  width: auto;
  height: 1px;
}
.rectangleDiv2{
  position: relative;
  border-radius: 0px 0px 15px 15px;
  background-color: #fff;
  box-shadow: 0px 1.9999998807907104px 5.5px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(21px);
  width: 100%;
  height: 64px;
  opacity: 0.95;
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.controlsHolder {
  display: flex;
    flex-direction: row;
  align-items: center;
}
.leftIconHolder {
  position: relative;
  border-radius: 5px;
  background-color: #f3f3f6;
  width: 26px;
  height: 26px;
  margin-left: 27px;
}
.leftIcon {
}
.rightIconHolder {
  position: relative;
  border-radius: 5px;
  background-color: #f3f3f6;
  width: 26px;
  height: 26px;
  margin-left: 5px;
}
.navText1{
  position: relative;
  font-size: 12px;
  line-height: 140%;
  font-family: 'Exo 2', sans-serif;
  color: #8c98a7;
  text-align: left;
  margin-left: 10px;
}
.selectIconHolder {
    position: relative;
    border-radius: 5px;
    background-color: #f3f3f6;
    width: 26px;
    height: 26px;
    margin-left: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.selectIcon {
  width: 18px;
  height: 18px;
}