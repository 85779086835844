@import "../../components/global.scss";

.buttonGroup {
    display: flex;
    background-color: #f5f5f5;
    border-radius: 30px;
    padding: 5px;
    overflow: hidden;
    max-width: 675px;
    margin: 0 auto;

    .button {
        flex: 1;
        padding: 10px 20px;
        border: none;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        cursor: pointer;
        background-color: transparent;
        transition: background-color 0.3s ease;

        &.active {
            background-color: $color-brand-primary;
            color: #ffffff;
            border-radius: 50px;
        }

        &:not(.active) {
            color: #333333;
            background-color: transparent;
            border-radius: 50px;
        }

        &:focus {
            outline: none;
            border-radius: 50px;
        }
    }
}