.bgImg {
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  z-index: -1;
}

.aiDrivenEsgAnalytics {
  position: relative;
  line-height: 120%;
  display: inline-block;
  width: 598px;
}
.exploreData1 {
  position: relative;
  font-size: 24px;
  line-height: 140%;
  font-weight: 500;
  color: #5c504f;
  display: inline-block;
  width: 598px;
}
.aiDrivenEsgAnalyticsAtYouParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}
.text1 {
  font-weight: 600;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.buttonbase {
  cursor: pointer;
  border-radius: 16px;
  background-color: #ef6239;
  width: 186px;
  height: 48px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  color: #fff;
}
.frameParent {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 48px;
  text-align: left;
  font-size: 54px;
  color: #332d2b;
  font-family: 'Exo 2', sans-serif;
}

.contentWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10%;
  margin-left: 10%;
  margin-right: 9%;
}

.imgWrapper {
  margin-left: 10%;
  border-radius: 21px;
  width: 100%;
  object-fit: cover;
  box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.1);
}