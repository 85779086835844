@import 'components/global.scss';

.ico {

  position: relative;
  display: block;
  width: 1.4em;
  height: 1.4em;

}

.icon {
  height: 2em;
  width: 2em;
  overflow: inherit;
}
