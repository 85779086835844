// @import "../../../components/global/color.scss";

.buttonHolder {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.nextButton {
  align-self: flex-end;
}

.backButton {
}

.formRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.formWrapper {
  margin-left: 3em;
  margin-right: 3em;
  margin-top: 1em;
  margin-bottom: 2em;
}

.headerContent {
  max-width: 600px;
  margin-bottom: 20px;

  h1 {
    margin-bottom: 7px;
  }
}

.dollarAmount {
  width: 200px;
  text-align: right;
}

// .numericInput {
//   height: 34px;

//   b {
//     height: 30px;
//     background: $color-brand-primary !important;
//   }
// }
