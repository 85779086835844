@import "../../../components/global.scss";

/* ----------------------------------------------
 * Generated by Animista on 2023-8-18 21:57:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}



.map-wrap {
  //position: absolute;
  //margin-left: $navWidth;
  //width: calc(100vw - $navWidth);
  //height: calc(100vh - $headerHeight); /* calculate height of the screen minus the heading */
  //  height: 100%;
  //width: 100%;
}

.map {
  position: relative;
  //bottom: $headerHeight;
  //left: $navWidth;
  //width: 100%;
  //height: 100%; /* calculate height of the screen minus the heading */
  width: calc(100vw - $navWidth);
  height: calc(100vh - $headerHeight); /* calculate height of the screen minus the heading */

  //.mapboxgl-ctrl-logo {
  //  display: none;
  //}
}
.marker {
    position: absolute;
    width: 40px;
    height: 40px;
    left: -20px;
    top: -20px;
    background-image: url('https://i.imgur.com/8JWzZ4Q.png');
    background-size: cover;
    cursor: pointer;
}
.marker1 {
  position: relative;
  border-radius: 50%;
  background-color: rgba(127, 174, 23, 0.35);
  width: 80px;
  height: 80px;
}
.markerEllipse {
  -webkit-animation: puff-in-center 0.5s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
  animation: puff-in-center 0.5s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  background-color: rgba(127, 174, 23, 0.35);
  width: 80px;
  height: 80px;
}
.markerInnerEllipse {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #7fae17;
  box-sizing: border-box;
  width: 42.67px;
  height: 42.67px;
}
.markerIcon {
  position: relative;
  width: 100%;
  height: 22px;
  overflow: hidden;
}