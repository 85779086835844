@import "../../../components/global.scss";

.progress-indicator {
    display: flex;
    align-items: center;
    background-color: #F5F5F5;
    border: 1px solid $c-border-dark-input;
    padding: 8px;
    height: 56px;
    border-radius: 50px;
    gap: 12px;

    &__step {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-right: 12px;

        &.active {
            .progress-indicator__icon-wrapper {
                background-color: $color-brand-primary;
            }

            .progress-indicator__step-text {
                color: #333333;
                font-weight: bold;
            }
        }

        .progress-indicator__icon-wrapper {
            background-color: #eaeaea;
            border-radius: 50%;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .progress-indicator__icon {
            font-size: 18px;

            &.active {
                color: #ffffff;
            }

            &:not(.active) {
                color: #666666;
            }
        }

        .progress-indicator__step-text {
            margin-left: 10px;

            span {
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
            }

            p {
                margin: 0;
                font-size: 14px;
                color: #212121;
                font-weight: 600;
            }
        }

        .progress-indicator__divider {
            height: 24px;
            width: 1px;
            background-color: #d3d3d3;
            position: absolute;
            right: 0px;
        }
    }

    &__step:last-child .progress-indicator__divider {
        display: none;
    }
}