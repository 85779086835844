@import '../../components/global.scss';

.header {

  position: relative;
  width: auto;
  z-index: 10;
  background: white;
  height: $headerHeight;
  display: flex;
  flex-direction: row;
  align-items: center;
  //justify-content: center;
  //margin: 0 0 0 $navWidth;
  //padding: $scale2;
  //margin: $scale2*-1 $scale2*-1 $scale2 $scale2*-1;

  @media (max-width: $mobile) {

    color: $c-text;
    text-align: center;
    background: white;
    padding: $scale $scale;
    border: none;

  }
}

.title {
  position: relative;
  margin-left: 15px;
  font-size: 23px;
  line-height: 140%;
  font-family: 'Exo 2', sans-serif;
  font-weight: 300;
  color: #2d3748;
  text-align: left;
}