@import 'components/global.scss';

.bar {
  padding-top: 2em;
  padding-bottom: 1em;
}

.step {

  display: block;
  text-align: center;
}

.dot {
  height: 1.5em;
  width: 1.5em;
  border: 2px solid $c-bg;
  border-radius: 50%;
  margin: 0 auto;
  background-color: $c-lightgray;

}

.accomplished {

}

.accomplished_dot {
  background-color: $c-primary;

}

.in_progress {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.complete:before {

  background-color: $c-green !important;

}


.label {
  padding-top: 2.9em;
  height: 2em;
  //display: flex;
  //left: -.75em;


  div {
    display: flex;
    justify-content: center;
  }
}

.step_icon {
  left: -.2em;
  position: absolute;
  top: -3em;
  overflow: unset;
}
